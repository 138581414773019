import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop
} from "@progress/kendo-react-listbox";
import { CourseItem } from "./CourseItem";
import {initElectiveCourseOptions, initCriminalLawPreferences, initPropertyLawPreferences} from "../helper";

const SELECTED_FIELD = "selected";
export const ElectivePreference = (data, electiveCourseOptions) => {   
    
  const [state, setState] = React.useState({
    electiveCourseOptions: initElectiveCourseOptions(data),    
    electivePreferences: data.electivePreferences,
    criminalLawPreferences: initCriminalLawPreferences(data),
    propertyLawPreferences: initPropertyLawPreferences(data),
    draggedItem: {},
  });

  const handleItemClick = (event, data, connectedData) => {
    setState({
      ...state,
      [data]: state[data].map((item, index) => {
        if (item.id === event.dataItem.id) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item, index) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleToolBarClick = (e, formRenderProps) => {
    let toolName = e.toolName || "";
    let result = processListBoxData(
      state.electiveCourseOptions,
      state.electivePreferences,
      toolName,
      SELECTED_FIELD
    );

    result.listBoxTwoData = result.listBoxTwoData.map((item, index)  => { return {...item, order: index + 1} });
    result.listBoxOneData = result.listBoxOneData.map((item, index)  => { return {...item, order: 0} });
    setState({
      ...state,
      electiveCourseOptions: result.listBoxOneData,
      electivePreferences: result.listBoxTwoData,
    });
    formRenderProps.onChange("electiveCourseOptions", { value: result.listBoxOneData.map(a => a)});  
    formRenderProps.onChange("electivePreferences", { value: result.listBoxTwoData.map(a => a)});
  };

  const handleDragStart = (e) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const handleDrop = (e, formRenderProps) => {
    let result = processListBoxDragAndDrop(
      state.electiveCourseOptions,
      state.electivePreferences,
      state.draggedItem,
      e.dataItem,
      "id"
    );

    result.listBoxTwoData = result.listBoxTwoData.map((item, index)  => { return {...item, order: index + 1} });
    result.listBoxOneData = result.listBoxOneData.map((item, index)  => { return {...item, order: 0} });

    setState({
      ...state,
      electiveCourseOptions: result.listBoxOneData,
      electivePreferences: result.listBoxTwoData,
    });    
    //debugger;    
    formRenderProps.onChange("electiveCourseOptions", { value: result.listBoxOneData.map(a => a)});  
    formRenderProps.onChange("electivePreferences", { value: result.listBoxTwoData.map(a => a)});
  };  
   
  return (formRenderProps) => (
    <div className="" >                    
          {/* {data.dragItem} */}
        <div className="row justify-content-center preference-section k-content wide">
          <div className="row  justify-content-center "> 
          <img
              src="https://demos.telerik.com/kendo-ui/content/web/listbox/arrow-left2right.png"
              alt="KendoReact ListBox Left Arrow"
              className="arrow"
              style={{width: 'auto'}}
            />
          </div>
          
            <br />          
            <div className="col"> 
                <h6>Course Options</h6>    
                <Field
                key={"electiveCourseOptions"}
                id={"electiveCourseOptions"}
                name={"electiveCourseOptions"}                
                textField="name"                                
                component={ListBox}          
                onDragStart={handleDragStart}
                onDrop={                     
                  (e) => handleDrop(e, formRenderProps)                                      
                }
                data={ state.electiveCourseOptions ?? [] }
                selectedField={SELECTED_FIELD}
                onItemClick={(e) => 
                  handleItemClick(e, "electiveCourseOptions", "electivePreferences")
                }
                //validator={emailValidator}
                item={CourseItem}               
                />      
          </div>       
          <div className="col"> 
            <h6>Select and order by preference</h6>  
                <Field
                  key={"electivePreferences"}
                  id={"electivePreferences"}
                  name={"electivePreferences"}                                    
                  component={ListBox}          
                  data={ state.electivePreferences ?? []}
                  selectedField={SELECTED_FIELD}
                  onItemClick={(e) =>                    
                      handleItemClick(e, "electivePreferences", "electiveCourseOptions")                       
                  }
                  // style={{
                  //   marginLeft: "12px",
                  // }}
                  onDragStart={handleDragStart}
                  onDrop={                     
                    (e) => handleDrop(e, formRenderProps)                                        
                  }
                  item={CourseItem}
                  toolbar={() => {
                    return (
                      <div style={{ margin: 5 }}> 
                        <ListBoxToolbar                      
                          tools={[
                            "moveUp",
                            "moveDown",
                            "transferTo",
                            "transferFrom",
                            //"transferAllTo",
                            //"transferAllFrom",
                            //"remove",
                          ]}
                          
                          data={state.electiveCourseOptions}
                          dataConnected={state.electivePreferences}
                          onToolClick={ (e) => 
                              handleToolBarClick(e, formRenderProps)                            
                          }
                        />
                      </div>
                    );
                  }}
                //validator={emailValidator}
              />  
            </div> 
          <div className="row  justify-content-center "> 
            <img
                src="https://demos.telerik.com/kendo-ui/content/web/listbox/arrow-right2left.png"
                alt="KendoReact ListBox Right Arrow"
                className="arrow"
                style={{width: 'auto'}}
              />
          </div>
        
        </div>     
    </div>
  );
};