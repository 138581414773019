import logo from "./logo.svg";
import "./App.css";
import { PageLayout } from "./components/PageLayout";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Button from "react-bootstrap/Button";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import React, { useState } from "react";
import { TriggerEvents } from "./pages/trigerEvents/TriggerEvents";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { SignInButton } from "./components/SignInButton";

function App() {
  console.log("process.env", process.env);
  //throw Error("test");
  return (
    <BrowserRouter>
      <PageLayout>
        <AuthenticatedTemplate>
          {/* <TriggerEvents /> */}
          {/* <ProfileContent /> */}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate></UnauthenticatedTemplate>
      </PageLayout>
    </BrowserRouter>
  );
}

export default App;
