import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useParams } from "react-router-dom";
import ApiStatus from "../../components/ApiStatus";
import { FirstYearPreferenceForm, FirstYearPreferenceFormSteps } from "./FirstYearPreferenceForm";
import { useCreateFirstYearPreferences, useUpdateFirstYearPreference, useFetchCourses, useFirstYearPreference } from "../../services/firstYearPreferenceService";

export const FirstYearPreferenceEditHelper = ({data}) => {
  const { id, stepId } = useParams();
  if (!id) throw Error("Trigger Event id not found");
  const steps = FirstYearPreferenceFormSteps;
  
  const updateEventMutation = useUpdateFirstYearPreference(Number(stepId), steps.length - 1);
  const { data: ecData, status : ecStatus, isSuccess : ecIsSuccess, isError : ecIsError, error: ecError } = useFetchCourses(data.termId);  
  
  if (!ecIsSuccess) return <ApiStatus status={ecStatus}></ApiStatus>;
  if (!data || !ecData) return <div> Not found.</div>;      
  return (
    <>            
      <FirstYearPreferenceForm
        data={{ ...data, courseOptions: ecData}}                
        stepsInit= {steps}
        eventMutation = {updateEventMutation}                
      />
    </>
  );
};

export const FirstYearPreferenceEdit = (props) => {
  const { id, stepId } = useParams();
  if (!id) throw Error("Trigger Event id not found");
  const steps = FirstYearPreferenceFormSteps;

  const { data, status, isSuccess, isError, error } = useFirstYearPreference(id);    

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;  
  return (
    <>            
      <FirstYearPreferenceEditHelper
        data={{ ...data }}         
      />
    </>
  );
};

