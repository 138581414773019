//import './CourseEvaluations.css';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { WithState } from "../../components/withState.js";
import { useFetchTriggerEvents } from "../../services/triggerEventService.js";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { filterBy,toDataSourceRequest } from "@progress/kendo-data-query";
import {
  Filter,
  Operators,
  TextFilter,
  NumericFilter,
  BooleanFilter,
} from "@progress/kendo-react-data-tools";

// import { DropdownFilterCell } from "../components/DropdownFilterCell.js";
// import {
//   ColumnMenu,
//   ColumnMenuCheckboxFilter,
// } from "../components/ColumnMenu.js";

// import { useNavigate } from "react-router-dom";
import {
useDeleteTriggerEvent
} from "../../services/triggerEventService.js";
const StatefullGrid = WithState(Grid);

export const TriggerEvents = () => {
  const filterOperators = {
    text: [
      {
        text: "grid.filterContainsOperator",
        operator: "contains",
      },
      { text: "grid.filterIsEmptyOperator", operator: "isempty" },
      { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
    ],
    numeric: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterGteOperator", operator: "gte" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    date: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
      { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    boolean: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
    ],
  };

  let [searchParams, setSearchParams] = useSearchParams();
  const deleteTriggerEventMutation = useDeleteTriggerEvent();

  const enterEdit = (item) => {
    navigate(`${item.id}/edit`);
  };

  const enterView = (item) => {
    navigate(`${item.id}`);
  };


  const EditCommandCell = (props) => {
    return (
      <td>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => props.enterEdit(props.dataItem)}
        >
          <span className="k-icon k-i-edit"></span>  
          Edit
        </button>
      </td>
    );
  };

  const ViewCommandCell = (props) => {
    return (
      <td>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => props.enterView(props.dataItem)}
        >
          <span className="k-icon k-i-hyperlink-open"></span>  
          View
        </button>
      </td>
    );
  };

  const DeleteCommandCell = (props) => {
    return (
      <td>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-warning"
          disabled={props.dataItem.triggerAt < new Date()}
          onClick={() => props.onDelete(props.dataItem)}
        >
          <span className="k-icon k-i-delete"></span>          
          Delete
        </button>
      </td>
    );
  };

  const RecordEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const RecordViewCommandCell = (props) => (
    <ViewCommandCell {...props} enterView={enterView} />
  );

  const RecordDeleteCommandCell = (props) => (    
    <DeleteCommandCell {...props} onDelete={ deleteTriggerEventMutation.mutate } />
  );

  const [gridDataState, setGridDataState] = React.useState(          
    {
    sort: [
      { field: "triggerAt", dir: "desc" }
    ],
    skip: 0,
    take: 10,
  });
  const navigate = useNavigate();
  return (
    <>      
      <div style={{margin:'10px 0px'}}>
        <Link className="btn btn-primary" role="button" to="new"> New </Link>      
      </div>      
      <StatefullGrid
        apiDataSource={useFetchTriggerEvents}
        filterOperators={filterOperators}
        sortable={true}
        pageable={true}
        resizable={true}
        minResizableWidth={30}
        //onRowClick={NavigateToCourseEvaluate}
        dataState={gridDataState}
        setGridDataState={setGridDataState}
        updateURLSearchParams = {true}
      >
        {/* <GridColumn field="id" title="id" filterable={false} /> */}
        <GridColumn
          field="triggerAt"
          title="Triggere At"
          filterable={false}
          format="{0:dd-MMM-yyyy HH:mm Z}"
        />
        <GridColumn
          field="createdByUser.email"
          title="Triggered By Email"
          filterable={false}
        />
        <GridColumn field="status" title="Status" filterable={true} width={"200px"} />
        <GridColumn field="payloadJson" title="Pay Load" filterable={true} />
        <GridColumn field="notes" title="Notes" />
        <GridColumn field="errors" title="Errors" filterable={false} />        
        <GridColumn cell={RecordViewCommandCell} filterable={false}  width={"110px"}/>
        <GridColumn cell={RecordEditCommandCell} filterable={false}  width={"100px"}/>
        <GridColumn cell={RecordDeleteCommandCell} filterable={false} width={"120px"} />
      </StatefullGrid>
    </>
  );
};
