import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
export const CategoryDropDownCell = (props) => {
  const localizedData = [
    {
      text: "Elective",
      value: 'Elective',
    },
    {
      text: "Criminal Law",
      value: 'CriminalLaw',
    },
    {
      text: "Property Law",
      value: 'PropertyLaw',
    },
    {
        text: "None (Exclude)",
        value: 'None',
    }
  ];
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      });
    }
  };
  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  return (
    <td>
      {dataItem.inEdit ? (
        <DropDownList
          
          onChange={handleChange}
          value={localizedData.find((c) => c.value === dataValue)}
          data={localizedData}
          textField="text"
        />
      ) : dataValue }
    </td>
  );
};