//import './CourseEvaluations.css';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { WithState } from "../../components/withState.js";
import {useFirstYearPreferences, useCreateFirstYearPreferences } from "../../services/firstYearPreferenceService.js";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { filterBy } from "@progress/kendo-data-query";
import {
  Filter,
  Operators,
  TextFilter,
  NumericFilter,
  BooleanFilter,
} from "@progress/kendo-react-data-tools";

// import { DropdownFilterCell } from "../components/DropdownFilterCell.js";
// import {
//   ColumnMenu,
//   ColumnMenuCheckboxFilter,
// } from "../components/ColumnMenu.js";

// import { useNavigate } from "react-router-dom";
import {
  useDeleteFirstYearPreference
} from "../../services/firstYearPreferenceService";

const StatefullGrid = WithState(Grid);

export const FirstYearPreferences = () => {
  const filterOperators = {
    text: [
      {
        text: "grid.filterContainsOperator",
        operator: "contains",
      },
      { text: "grid.filterIsEmptyOperator", operator: "isempty" },
      { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
    ],
    numeric: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterGteOperator", operator: "gte" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    date: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
      { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    boolean: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
    ],
  };

  const deleteMutation = useDeleteFirstYearPreference();
  const createMutation = useCreateFirstYearPreferences();

  const enterEdit = (item) => {
    navigate(`${item.id}/edit&step=0`);
  };

  const enterView = (item) => {
    navigate(`${item.id}`);
  };


  const EditCommandCell = (props) => {
    return (
      <td>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => props.enterEdit(props.dataItem)}
        >
          <span className="k-icon k-i-edit"></span>  
          Edit
        </button>
      </td>
    );
  };

  const ViewCommandCell = (props) => {
    return (
      <td>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => props.enterView(props.dataItem)}
        >
          <span className="k-icon k-i-hyperlink-open"></span>  
          View
        </button>
      </td>
    );
  };

  const DeleteCommandCell = (props) => {
    return (
      <td>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-warning"
          // disabled={props.dataItem.triggerAt < new Date()}
          onClick={() => props.onDelete(props.dataItem)}
        >
          <span className="k-icon k-i-delete"></span>          
          Delete
        </button>
      </td>
    );
  };

  const RecordEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const RecordViewCommandCell = (props) => (
    <ViewCommandCell {...props} enterView={enterView} />
  );

  const RecordDeleteCommandCell = (props) => (    
    <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
  );

  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "createdAt", dir: "desc" }
    ],
    skip: 0,
    take: 10,
  });
  const navigate = useNavigate();
  return (
    <>      
      <div style={{margin:'10px 0px'}}>
        <button className="btn btn-primary"  onClick={() => createMutation.mutate() }> New </button>      
        <Link className="btn btn-primary" style={{marginLeft:'10px'}} role="button" to="courses?sort=srsNumber-asc&page=1&pageSize=10"> Course </Link>      
        <Link className="btn btn-primary" style={{marginLeft:'10px'}} role="button" to="enrollments"> Enrollments </Link>      
      </div>      
      <StatefullGrid
        apiDataSource={useFirstYearPreferences}
        filterOperators={filterOperators}
        sortable={true}
        pageable={true}
        resizable={true}
        minResizableWidth={30}
        //onRowClick={NavigateToCourseEvaluate}
        dataState={gridDataState}
        setGridDataState={setGridDataState}
        updateURLSearchParams = {true}
      >
        {/* <GridColumn field="id" title="id" filterable={false} /> */}            
        <GridColumn field="termId" title="Term Id" filterable={true} />                
        <GridColumn field="status" title="Status" filterable={true} width={"200px"} />
        <GridColumn field="createdByUser.firstName" title="First Name" filterable={true} width={"200px"} />
        <GridColumn field="createdByUser.lastName" title="Last Name" filterable={true} width={"200px"} />
        <GridColumn field="createdByUser.employeeId" title="Student Id" filterable={true} width={"200px"} />        
        <GridColumn
          field="createdAt"
          title="Created At"
          filterable={false}
          format="{0:dd-MMM-yyyy HH:mm Z}"
        /> 
        <GridColumn cell={RecordViewCommandCell} filterable={false}  width={"110px"}/>
        <GridColumn cell={RecordEditCommandCell} filterable={false}  width={"100px"}/>
        <GridColumn cell={RecordDeleteCommandCell} filterable={false} width={"120px"} />
      </StatefullGrid>
    </>
  );
};
