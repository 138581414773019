const ValidationSummary = ({ error }) => {    
  if(error.response === undefined)
  return <div className={"k-messagebox k-messagebox-error"} >{error.message}</div>
  if (error.response?.status === 403) return <> <div className={"k-messagebox k-messagebox-error"} >Forbidden access. You don't have permission to perform this action.</div></>;
  if (![400, 409].includes(error.response?.status)) return <></>;
  if(error.response?.status === 409)return <><p>Id Conflict, can't processes.</p></>;
  const errors = error.response?.data?.errors ?? error.response?.data;
  //debugger;
  //return (<></>);
  return (
    <>
    <div className={"k-messagebox k-messagebox-error"}>
      <h6><span className="k-icon k-i-error"></span>{"   "}Validation Summary:</h6>
      { Object.entries(errors).map(([key, value]) => (
        <ul key={key}>
          <li className="">
             {Array.isArray(value)? value?.join(", "): value}
          </li>
        </ul>
      ))}
      </div>
    </>
  );
};

export default ValidationSummary;
