import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useCreateTriggerEvent } from "../../services/triggerEventService";
import { TrigerEvenForm } from "./TrigerEventForm";
import ValidationSummary from "../../components/ValidationSummary";
import ApiStatus from "../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";

export const TrigerEventNew = (props) => {
  const createTriggerEventMutation = useCreateTriggerEvent();
  const trigerEvent = {
    id: uuidv4(),
    triggerAt: new Date(new Date().getTime() + 1 * 60000),
  };

  return (
    <>
      <TrigerEvenForm
        trigerEvent={trigerEvent}
        triggerEventMutation={createTriggerEventMutation}
        isNew={true}
        // submitted={(t) => createTriggerEventMutation.mutate(t)}
      />
    </>
  );
};

// TrigerEventEdit.propTypes = {
//   id: PropTypes.string.isRequired,
//   data: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     triggeredBy: PropTypes.object.isRequired,
//   }).isRequired,
// };

// TrigerEventEdit.propTypes = {
//   triggeredBy: PropTypes.func.isRequired,
//   courses: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       title: PropTypes.string.isRequired,
//       authorId: PropTypes.number.isRequired,
//       category: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };
