import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useParams } from "react-router-dom";
import ApiStatus from "../../components/ApiStatus";
import { ScholarshipForm } from "./ScholarshipForm";
import { useFetchScholarship, useUpdateScholarship } from "../../services/scholarshipService";

export const ScholarshipEdit = (props) => {
  const { id, stepId } = useParams();
  if (!id) throw Error("Trigger Event id not found");
  const steps = [
    {
      label: "Personal Info",
      isValid: undefined,
    },
    {
      label: "Personal Details",
      isValid: undefined,
    },
    {
      label: "Payment Details",
      isValid: undefined,
    },
  ];
  const { data, status, isSuccess, isError, error } = useFetchScholarship(id);  
  const updateEventMutation = useUpdateScholarship(Number(stepId), steps.length - 1);

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;

  return (
    <>            
      <ScholarshipForm
        data={data}                
        stepsInit= {steps}
        eventMutation = {updateEventMutation}                
      />
    </>
  );
};

// TrigerEventEdit.propTypes = {
//   id: PropTypes.string.isRequired,
//   data: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     triggeredBy: PropTypes.object.isRequired,
//   }).isRequired,
// };

// TrigerEventEdit.propTypes = {
//   triggeredBy: PropTypes.func.isRequired,
//   courses: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       title: PropTypes.string.isRequired,
//       authorId: PropTypes.number.isRequired,
//       category: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };
