import * as React from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { Error } from "@progress/kendo-react-labels";
import {
  useFetchTriggerEvent,
  useUpdateTriggerEvent,
} from "../../services/triggerEventService";
import { useParams } from "react-router-dom";
import { TrigerEvenForm } from "../trigerEvents/TrigerEventForm";
import ApiStatus from "../../components/ApiStatus";
import { useNavigate } from "react-router-dom";

import { PersonalInformation } from "./PersonalInformation";
import { PersonalDetails } from "./PersonalDetails";
import { PaymentDetails } from "./PaymentDetails";
import { useFetchFile,useUploadFile, useDeleteFile } from "../../services/uploadService";
import ValidationSummary from "../../components/ValidationSummary";

const stepPages = [PersonalInformation,PersonalDetails, PaymentDetails];
const initFormValue = {
  highSchoolCityState : 'test',
  files: []
}

export const ScholarshipForm = ({ data, stepsInit, eventMutation, isNew = false }) => {  
  //const [step, setStep] = React.useState(stepId ?? 0);
  const { stepId } = useParams();
  //console.log(stepId);
  const step = Number(stepId ? stepId : 0 );  
  const [formState, setFormState] = React.useState(data);
  //const [files, setFiles] = React.useState(data.files);
  const [steps, setSteps] = React.useState(stepsInit);
  const nav = useNavigate();

  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;

  const onStepSubmit = React.useCallback(
    (event) => {
      const { isValid, values } = event;
      const currentSteps = steps.map((currentStep, index) => ({
        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));

      setSteps(currentSteps);

      if (!isValid) {
        return;
      }
      
      //setFormState({...values });       
      eventMutation.mutate({...values, expectedGraduationYear: values.expectedGraduationYear.id });                   
      
      if (isLastStep && isPreviousStepsValid && isValid) {
        alert(JSON.stringify(values));
      }
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
  );
  const onPrevClick = (e) => {    
    e.preventDefault();
    nav(`/scholarships/${data.id}/edit&step=${Math.max(step - 1, 0)}`)    
  };

  const requestIntervalRef = React.useRef({});
  const progressRef = React.useRef({});
  //debugger;
  const uploadFile = useUploadFile(requestIntervalRef, progressRef, formState.id);
  const deleteFile = useDeleteFile(requestIntervalRef, progressRef);
  const fetchFile = useFetchFile();
  const curStep = stepPages[step](data, null, fetchFile, uploadFile, deleteFile)
  return (
    <>      
      {/* <TrigerEvenForm
        trigerEvent={data}        
        triggerEventMutation = {updateTriggerEventMutation}                
      /> */}
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stepper value={step} items={steps} />      
      <Form
        initialValues={formState}
        onSubmitClick={onStepSubmit}
        render={(formRenderProps) => (
          <div
            style={{
              alignSelf: "center",
            }}
          >
            <FormElement
              style={{
                width: 480,
              }}
            >
              <div style={{minHeight:40, paddingTop:20}}>
                {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
                {formRenderProps.visited &&
                  formRenderProps.errors &&
                  formRenderProps.errors.VALIDATION_SUMMARY && (
                    <div className={"k-messagebox k-messagebox-error"}>
                      {formRenderProps.errors.VALIDATION_SUMMARY}?
                    </div>
                  )}   
              </div>

              {curStep(formRenderProps)}

              <span
                style={{
                  marginTop: "40px",
                }}
                className={"k-form-separator"}
              />
              <div
                style={{
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
                className={
                  "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                }
              >
                <span
                  style={{
                    alignSelf: "center",
                  }}
                >
                  Step {step + 1} of 3
                </span>
                <div>
                  {step !== 0 ? (
                    <Button
                      style={{
                        marginRight: "16px",
                      }}
                      onClick={onPrevClick}
                    >
                      Previous
                    </Button>
                  ) : undefined}
                  <Button
                    themeColor={"primary"}
                    disabled={isLastStep ? !isPreviousStepsValid : false}
                    onClick={formRenderProps.onSubmit}
                  >
                    {isLastStep ? "Submit" : "Next"}
                  </Button>
                </div>
              </div>
            </FormElement>
          </div>
        )}
      />
    </div>
    </>
  );
};
