import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop
} from "@progress/kendo-react-listbox";
import { CourseItem } from "./CourseItem";
import {initElectiveCourseOptions, initCriminalLawPreferences, initPropertyLawPreferences} from "../helper";

const SELECTED_FIELD = "selected";
export const CriminalLawPreference = (data) => {   
  //debugger;
  // console.log('d:');
  // console.log(data.criminalLawPreferences);
  const [state, setState] = React.useState({        
    electiveCourseOptions: initElectiveCourseOptions(data),    
    electivePreferences: data.electivePreferences,
    criminalLawPreferences: initCriminalLawPreferences(data),
    propertyLawPreferences: initPropertyLawPreferences(data),
    draggedItem: {},
  });

  const handleItemClick = (event, dataAttrName, formRenderProps) => {
    //setState(data);
    setState({
      ...state,
      [dataAttrName]: state[dataAttrName].map((item, index) => {
        if (item.id === event.dataItem.id) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),      
    });
    formRenderProps.onChange("criminalLawPreferences", { value: state[dataAttrName]});
  };

  const handleToolBarClick = (e, formRenderProps) => {
    let toolName = e.toolName || "";
    let result = processListBoxData(
      [],
      state.criminalLawPreferences,
      toolName,
      SELECTED_FIELD
    );

    result.listBoxTwoData = result.listBoxTwoData.map((item, index)  => { return {...item, order: index + 1} });

    setState({
      ...state,      
      criminalLawPreferences: result.listBoxTwoData,
    });    
    formRenderProps.onChange("criminalLawPreferences", { value: result.listBoxTwoData.map(a => a)});
  };

  const handleDragStart = (e) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const handleDrop = (e, formRenderProps) => {
    let result = processListBoxDragAndDrop(
      [],
      state.criminalLawPreferences,
      state.draggedItem,
      e.dataItem,
      "id"
    );

    result.listBoxTwoData = result.listBoxTwoData.map((item, index)  => { return {...item, order: index + 1} });    

    setState({
      ...state,      
      criminalLawPreferences: result.listBoxTwoData,
    });        
    formRenderProps.onChange("criminalLawPreferences", { value: result.listBoxTwoData.map(a => a)});
  };  
   
  return (formRenderProps) => (
    <div className="" style={{paddingTop:40}}>                             
        <div className="row justify-content-center preference-section k-content wide">                                
          <div className="col"> 
            <h6>Order by preference test</h6>  
                <Field
                  key={"criminalLawPreferences"}
                  id={"criminalLawPreferences"}
                  name={"criminalLawPreferences"}                                    
                  component={ListBox}          
                  data={ state.criminalLawPreferences ?? []}
                  selectedField={SELECTED_FIELD}
                  onItemClick={(e) =>                    
                      handleItemClick(e, "criminalLawPreferences", formRenderProps)                       
                  }                  
                  onDragStart={handleDragStart}
                  onDrop={                     
                    (e) => handleDrop(e, formRenderProps)                                        
                  }
                  item={CourseItem}
                  toolbar={() => {
                    return (
                      <div style={{ margin: 5 }}> 
                        <ListBoxToolbar                      
                          tools={[
                            "moveUp",
                            "moveDown",
                            //"transferTo",
                            //"transferFrom",
                            //"transferAllTo",
                            //"transferAllFrom",
                            //"remove",
                          ]}
                          
                          data={state.options}
                          dataConnected={state.criminalLawPreferences}
                          onToolClick={ (e) => 
                              handleToolBarClick(e, formRenderProps)                            
                          }
                        />
                      </div>
                    );
                  }}                
              />  
            </div>                  
        </div>     
    </div>
  );
};