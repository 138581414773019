import React, { useState, useEffect } from "react";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import ApiStatus from "./ApiStatus";

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

const useGridQueiry = (apiFetch, dataState) => {
  var query = toDataSourceRequestString(dataState);
  //console.log(dataState, query);
  //console.log(`useGridQueiry, ${apiFetch.name} / ${query}`);
  return apiFetch(query);
};

const serializeFormQuery = (params) => {
  const request = {
    skip: parseInt(params.get("page"), 10) || 1,
    take: parseInt(params.get("pageSize"), 10) || 10,
    page: parseInt(params.get("page"), 10) || 1,
    pageSize: parseInt(params.get("pageSize"), 10) || 10,
    sort: [],
    filter: null,
    group: null,
    aggregate: null,
  };
  request.skip = (request.page - 1) * request.pageSize;

  const sort = params.get("sort");
  if (sort) {
    request.sort = sort.split(",").map((s) => {
      const parts = s.split("-");
      return {
        field: parts[0],
        dir: parts[1] || "asc",
      };
    });
  }

  const filter = params.get("filter");

  if (filter) {
    const filters = filter
      .replace("(", "")
      .replace(")", "")
      .split("~and~")
      .map((filterExpression) => {
        const filterParts = filterExpression.split("~");
        const field = filterParts[0];
        const operator = filterParts[1];

        let value = decodeURIComponent(filterParts[2]).replace(/'/g, "");
        if (filterParts[2].indexOf("'") < 0) {
          value = parseFloat(value);
        }
        return {
          field,
          operator,
          value,
        };
      });
    if (filters.length > 0) {
      request.filter = {
        logic: "and",
        filters: filters,
      };
    }
  }

  return request;
};

export function WithState(WrappedGrid) {
  return function StatefullGrid(props) {
    //console.log("StatefullGrid", props.dataState);
    //const isFetching = useIsFetching()
    const [searchParams, setSearchParams] = useSearchParams();
    const { data, total, status, isSuccess } = useGridQueiry(
      props.apiDataSource,
      props.updateURLSearchParams
        ? serializeFormQuery(searchParams)
        : props.dataState
    );
    const nav = useNavigate();

    function handleDataStateChange(changeEvent) {
      //debugger;
      //console.log(`handleDataStateChange`);
      if (props.updateURLSearchParams) {
        nav(`?${toDataSourceRequestString(changeEvent.dataState)}`);
      }
      props.setGridDataState(changeEvent.dataState);
      //queryClient.invalidateQueries('getSummaryReport')
    }

    React.useEffect(
      function effectFunction() {
        if (data) {
          props?.setGridData && props?.setGridData(data);
        }
      },
      [data]
    );

    if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
    if (!data) return <div> Not found.</div>;

    return (
      <div style={{ height: "100%" }}>
        {/* { isFetching && loadingPanel } */}
        <WrappedGrid
          filterable={true}
          sortable={true}
          pageable={{ pageSizes: true }}
          total={data.total}
          data={props?.gridData?.data ?? data}
          skip={props.dataState?.skip}
          pageSize={props.dataState?.take}
          filter={props.dataState?.filter}
          sort={props.dataState?.sort}
          onDataStateChange={handleDataStateChange}
          apiDataSource={props.apiDataSource}
          updateURLSearchParams={props.updateURLSearchParams}
          {...props}
        />
        {/* <span>Total: {props?.gridData?.total}</span> */}
      </div>
    );
  };
}
