import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useFetch, useFetchWithPost } from "./useFetch";
import { usePut } from "./usePut";
import { usePost } from "./usePost";
import PropTypes from "prop-types";
import { useNotification } from "../components/notificationContext";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "./useDelete";
import { redirect } from "react-router-dom";

export const triggerEvents = [
  {
    id: "CanvasExamSubmissionFetchTriggerEvent",
    text: "Canvas Exam Submission Fetch",
  },
  { id: "TestTriggerEvent", text: "Test" },
];

export const useFirstYearPreferences = (query) => {
  return useFetch({
    url: `firstyearpreference`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return { ...r, submittedAt: new Date(r.submittedAt) };
        }),
      };
    },
    query: query,
  });
};

export const useFirstYearCourses = (query) => {
  return useFetch({
    url: `firstyearpreference/courses`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return { ...r, createdAt: new Date(r.createdAt) };
        }),
      };
    },
    query: query,
  });
};

export const useFetchCourses = (termId) => {
  return useFetch({
    url: `firstyearpreference/courses/${termId}`,
    dataMapper: (d) => {
      return d.map((r) => {
        return {
          ...r,
          id: r.srsNumber,
          isEnrolled: false,
          //countEnrolled: 0,
          curStudentPreference: 0,
          interesetedStudents: [],
          popularity: 0,
          classMeetings: r.classMeetings.map((cm) => {
            return {
              ...cm,
              startTime: new Date(cm.classStartTime),
              endTime: new Date(cm.classStopTime),
            };
          }),
        };
      });
    },
  });
};

export const useUpdateCourse = (termId, srsNumber) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`firstyearpreference/course/{termId}/{srsNumber}`, {
    onSuccess: (_, record) => {
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      debugger;
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "error",
          text: `Failed to save the changes. ${Object.values(
            _?.response?.data
          )?.join(",")}`,
        },
      ]);
    },
  });
};

export const useFetchStudents = (termId) => {
  return useFetch({
    url: `firstyearpreference/students/${termId}`,
    dataMapper: (d) => {
      return d.map((r) => {
        //criminalLawPreferences
        return {
          ...r,
          //enrolledUnits: 0,
          coursePreferences: {
            ...r.createdFirstYearPreferences[0]?.criminalLawPreferences?.reduce(
              (pref, item) => {
                pref[item.srsNumber] = item;
                return pref;
              },
              {}
            ),
            ...r.createdFirstYearPreferences[0]?.electivePreferences?.reduce(
              (pref, item) => {
                pref[item.srsNumber] = item;
                return pref;
              },
              {}
            ),
            ...r.createdFirstYearPreferences[0]?.propertyLawPreferences?.reduce(
              (pref, item) => {
                pref[item.srsNumber] = item;
                return pref;
              },
              {}
            ),
          },
          firstYearEnrollments: new Set(
            r?.firstYearEnrollments?.map((en) => en.srsNumber)
          ),
          doesScheduledConflict: false,
        };
      });
    },
  });
};

export const useFetchEnrollments = (termId) => {
  return useFetch({
    url: `firstyearpreference/enrollments/${termId}`,
    dataMapper: (d) => d,
  });
};

export const useFirstYearPreference = (id) => {
  return useFetch({
    url: `firstyearpreference/${id}`,
    dataMapper: (d) => {
      return {
        ...d,
        electivePreferences: d.electivePreferences
          .map((r) => {
            return { ...r, selected: false, id: r.srsNumber };
          })
          .sort((a, b) => a.order - b.order),
        criminalLawPreferences: d.criminalLawPreferences
          .map((r) => {
            return { ...r, selected: false, id: r.srsNumber };
          })
          .sort((a, b) => a.order - b.order),
        propertyLawPreferences: d.propertyLawPreferences
          .map((r) => {
            return { ...r, selected: false, id: r.srsNumber };
          })
          .sort((a, b) => a.order - b.order),
      };
    },
  });
};

export const useUpdateFirstYearPreference = (stepId, lastStepIndex) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  const steps = [
    "saveElectivePreference",
    "saveCriminalLawPreference",
    "submit",
  ];

  return usePut(`firstyearpreference/{id}/${steps[stepId]}`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries([`firstyearpreference/${record.id}`]);
      //debugger;
      if (stepId === lastStepIndex) {
        nav("/firstyearpreferences");
      } else {
        nav(
          `/firstyearpreferences/${record.id}/edit&step=${Math.min(
            stepId + 1,
            lastStepIndex
          )}`,
          { replace: true }
        );
      }
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useUpdateEnrollments = (termId) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`firstyearpreference/enrollments/${termId}`, {
    onSuccess: (_, record) => {
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useCreateFirstYearPreferences = () => {
  const nav = useNavigate();
  return usePost(`firstyearpreference`, {
    onSuccess: (_, scholarship) => {
      nav(`/firstyearpreferences/${_.data.id}/edit&step=0`);
      return _;
    },
    onprogress: (_, scholarship) => {},
  });
};

export const useDeleteFirstYearPreference = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`firstyearpreference`, {
    onSuccess: (_, data) => {
      queryClient.removeQueries([`firstyearpreference/${data.id}`]);
      queryClient.invalidateQueries(["firstyearpreference"]);
      //queryClient.removeQueries(["firstyearpreference"]);
      nav(`/firstyearpreferences`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};
