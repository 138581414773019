import * as React from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

const types = ['pulsing', 'infinite-spinner', 'converging-spinner'];

export const PageMutating = () => {    
  const isMutating = useIsMutating()
  return isMutating ? <Loader size="medium" type='pulsing' /> : "";  
};
