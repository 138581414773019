import * as React from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

const types = ['pulsing', 'infinite-spinner', 'converging-spinner'];

export const GlobalLoader = () => {  
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  return <div className="loading" style={{ float: 'left', opacity : isFetching || isMutating ? 1 : 0 }}>      
          <div className='col-4'>
            <Loader type='pulsing' />
          </div>
        </div>    
};
