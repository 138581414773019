import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useParams } from "react-router-dom";
import ApiStatus from "../../../components/ApiStatus";
import { EnrollmentToolForm } from "./EnrollmentToolForm";
import { 
  useUpdateEnrollments, 
  useFetchCourses, 
  useFetchStudents,
  useFirstYearCourses,
  useFetchEnrollments } from "../../../services/firstYearPreferenceService";
  import { useFetchSetting, FIRST_YEAR_PREFERENCES_SETTING } from "../../../services/settingsService";

export const EnrollmentTool = (props) => {
  const { data: settings, status: settingsStatus, isSuccess: settingsSuccess, isError: settingsIsError, error: settingsError } = useFetchSetting(FIRST_YEAR_PREFERENCES_SETTING);    
  if (!settingsSuccess) return <ApiStatus status={settingsStatus}></ApiStatus>;

  return (
    <>            
      <EnrollmentToolHelper settings={settings} />
    </>
  );
}  

export const EnrollmentToolHelper = (props) => {
  // const { id } = useParams();
  // if (!id) throw Error("Trigger Event id not found");
  // const { data, status, isSuccess, isError, error } = useFirstYearCourses();    
  const { settings } = props;
  const { data: enrollments, status: enStatus, isSuccess: enSuccess, isError: enIsError, error: enError } = useFetchEnrollments(settings.currentTermId);  
  const {data: students, status : stStatus, isSuccess : stIsSuccess, isError : stIsError, error: stError } = useFetchStudents(settings.currentTermId);
  const updateEventMutation = useUpdateEnrollments(settings.currentTermId);
  const { data: courses, status : ecStatus, isSuccess : ecIsSuccess, isError : ecIsError, error: ecError } = useFetchCourses(settings.currentTermId);  
  
  if (!ecIsSuccess) return <ApiStatus status={ecStatus}></ApiStatus>;
  if (!stStatus) return <ApiStatus status={stStatus}></ApiStatus>;
  if (!enStatus) return <ApiStatus status={enStatus}></ApiStatus>;
  if (!courses || !students || !enrollments) return <div> Loading...</div>;      

  return (
    <>            
      <EnrollmentToolForm
        data={{ courses: courses, students: students, enrollments: enrollments}}                        
        eventMutation = {updateEventMutation}                
      />
    </>
  );
};