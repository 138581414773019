export const msalConfig = {
  auth: {
    clientId: "4b620e77-63a4-4a05-924e-e1bc4df30236",
    authority:
      "https://login.microsoftonline.com/e10a3d0f-a4fc-479d-9a50-c35e3f9e9bb5", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const recordsApiConfig = {
  scopes: ["api://b8c0ff17-ea0a-4dd6-b482-e08d8be36b0f/access_as_user"],
  account: null,
};
