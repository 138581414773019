import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useFetch } from "./useFetch";
import { usePut } from "./usePut";
import { usePost } from "./usePost";
import PropTypes from "prop-types";
import { useNotification } from "../components/notificationContext";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "./useDelete";

export const triggerEvents = [
  {
    id: "CanvasExamSubmissionFetchTriggerEvent",
    text: "Canvas Exam Submission Fetch",
  },
  { id: "TestTriggerEvent", text: "Test" },
];
export const FIRST_YEAR_PREFERENCES_SETTING = "FirstYearPreferencesSetting";
export const CANVAS_EXAM_SUBMISSION_FETCH_SETTING =
  "CanvasExamSubmissionFetchSetting";

export const useFetchSettings = (query) => {
  return useFetch({
    url: `setting`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data,
      };
    },
    query: query,
  });
};

export const useFetchSetting = (id) => {
  return useFetch({
    url: `setting/${id}`,
    dataMapper: (d) => {
      if (id === "FirstYearPreferencesSetting")
        return {
          ...d,
          availabilityStarts: new Date(d.availabilityStarts),
          availabilityEnds: new Date(d.availabilityEnds),
        };
      return d;
    },
  });
};

export const useUpdateSetting = (id) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return usePut(`setting/{id}`, {
    onSuccess: (_, data) => {
      queryClient.invalidateQueries([`setting/${id}`]);
      //nav(`/settings`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useDeleteSetting = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`setting`, {
    onSuccess: (_, data) => {
      queryClient.invalidateQueries([`setting/${data.id}`]);
      queryClient.invalidateQueries(["setting"]);
      nav(`/settings`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

// export const useCreateSetting = () => {
//   const { notifications, setNotifications } = useNotification();
//   const queryClient = useQueryClient();
//   const nav = useNavigate();
//   return usePost(`TriggerEvent`, {
//     onSuccess: (_, triggerEvent) => {
//       //queryClient.invalidateQueries([`TriggerEvent/${triggerEvent.id}`]);
//       nav(`/triggerevents`);
//       setNotifications([
//         ...notifications,
//         {
//           id: uuidv4(),
//           style: "success",
//           text: "Your data has been saved.",
//         },
//       ]);
//       //debugger;
//       //console.log(triggerEvent);
//       return _;
//     },
//     onprogress: (_, triggerEvent) => {},
//   });
// };
