import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { SignInButton } from "../../components/SignInButton";

export class HomePublic extends Component {
  render() {
    return (
      <div className="centered">
        <p>You are not signed in! Please sign in.</p>
        <ul className="unstyled">
          <Button>
            <SignInButton />
          </Button>
        </ul>
      </div>
    );
  }
}
