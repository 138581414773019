import * as React from "react";
import { SettingsEdit } from "./SettingsEdit";
import { CanvasExamSubmissionFetchSettingFormEdit } from "./CanvasExamSubmissionFetchSettingFormEdit";
import { FirstYearPreferencesSettingFormEdit } from "./FirstYearPreferencesSettingFormEdit";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FIRST_YEAR_PREFERENCES_SETTING,CANVAS_EXAM_SUBMISSION_FETCH_SETTING } from "../../services/settingsService";

export const SettingsPage = (props) => {    
  const tabStripListOrder = [
    "RecordsWebsite", 
    "CanvasExamSubmissionFetch",
    "FirstYearPreferences",
  ]
  const [selected, setSelected] = React.useState(0);  
  const { id } = useParams();
  const nav = useNavigate();
  const handleSelect = (e) => {
    //setSelected(e.selected);    
    nav("/settings/" + tabStripListOrder[e.selected]);
  };
  return (
    <>      
    <TabStrip selected={      
        ((id) => {
          return tabStripListOrder.indexOf(id) > -1 ? tabStripListOrder.indexOf(id) : 0
        })(id)      
    } onSelect={handleSelect}>
      <TabStripTab title="Website">
        <SettingsEdit settingId='RecordsWebsiteSetting' />
      </TabStripTab>
      <TabStripTab title="BruinLearn Exam Submission Fetch">
        <CanvasExamSubmissionFetchSettingFormEdit settingId={CANVAS_EXAM_SUBMISSION_FETCH_SETTING} />
      </TabStripTab>   
      <TabStripTab title="First Year Preferences">
        <FirstYearPreferencesSettingFormEdit settingId={FIRST_YEAR_PREFERENCES_SETTING} />
      </TabStripTab>      
    </TabStrip>           
    </>
  );
};
