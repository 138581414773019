import { useNotification } from "../components/notificationContext";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from "uuid";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { recordsApiConfig } from "../authConfig";
import { handleError } from "./apiUtils";

export const triggerEvents = [
  {
    id: "CanvasExamSubmissionFetchTriggerEvent",
    text: "Canvas Exam Submission Fetch",
  },
  { id: "TestTriggerEvent", text: "Test" },
];

export const useFetchFile = () => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };
  return async (id, fileName) => {
    try {
      const { accessToken } = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      const resp = await axios.get(
        `${process.env.REACT_APP_RECORD_API_URL}${"file/"}${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob", // important
        }
      );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      //fileDownload(resp.data.fileContents, resp.data.fileDownloadName);
    } catch (e) {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest);
      }
      //debugger;
      var errorText = e?.message;
      if (e?.message === "Network Error")
        errorText = `Network Error, can't connect.`;
      else if (e?.response?.status === 403) {
        errorText =
          "Forbidden access. You don't have permission to see this item.";
      }
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          text: errorText,
          style: "error",
        },
      ]);
      handleError(e, instance);
    } finally {
      // setLoading(false);?
    }
  };
  // return useFetch({
  //   url: `file/${id}`,
  //   dataMapper: (d) => {
  //     return {
  //       ...d,
  //       triggerAt: new Date(d.triggerAt),
  //       type: {
  //         id: d.type,
  //         text: triggerEvents.find((i) => i.id === d.type).text,
  //       },
  //       CanvasExamSubmissionFetchPayload: {
  //         FilteredAssignmentIds:
  //           d?.canvasExamSubmissionFetchPayload?.filteredAssignmentIds
  //             ?.map((a) => `"${a}"`)
  //             ?.join(","),
  //       },
  //     };
  //   },
  // });
};

export const useDeleteFile = (requestIntervalRef, progressRef) => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };

  return (files, options, onProgress) => {
    //debugger;
    const uid = files[0].uid;

    const saveRequestPromise = new Promise(async (resolve, reject) => {
      try {
        const { accessToken } = await instance.acquireTokenSilent(
          accessTokenRequest
        );

        const fileId = await axios.delete(
          `${process.env.REACT_APP_RECORD_API_URL}${"file/"}${uid}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        resolve({ uid: uid });
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(accessTokenRequest);
        }
        var errorText = (errorText = e?.response?.data?.Failed ?? e?.message);

        if (e?.message === "Network Error")
          errorText = `Network Error, can't connect.`;
        else if (e?.response?.status === 403) {
          errorText =
            "Forbidden access. You don't have permission to see this item.";
        }
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            text: errorText,
            style: "error",
          },
        ]);
        handleError(e, instance);
        reject({ uid: uid });
      }
    });

    return saveRequestPromise;
  };
};

export const useUploadFile = (
  requestIntervalRef,
  progressRef,
  scholarshipId
) => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };

  return (files, options, onProgress) => {
    //debugger;
    //return post;
    const uid = files[0].uid;

    progressRef.current[uid] = 0;

    // Simulate save request
    const saveRequestPromise = new Promise(async (resolve, reject) => {
      onProgress(uid, { loaded: progressRef.current[uid], total: 100 });
      let formData = new FormData();
      formData.append("file", files[0].getRawFile());

      try {
        const { accessToken } = await instance.acquireTokenSilent(
          accessTokenRequest
        );

        const fileId = await axios.post(
          `${
            process.env.REACT_APP_RECORD_API_URL
          }${"file/"}${uid}?type=scholarship&scholarshipId=${scholarshipId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        resolve({ uid: uid, name: files[0].name });

        delete progressRef.current[uid];
        clearInterval(requestIntervalRef.current[uid]);
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(accessTokenRequest);
        }
        var errorText = (errorText = e?.response?.data?.Failed ?? e?.message);

        if (e?.message === "Network Error")
          errorText = `Network Error, can't connect.`;
        else if (e?.response?.status === 403) {
          errorText =
            "Forbidden access. You don't have permission to see this item.";
        }
        reject({ uid: uid });
        progressRef.current[uid] = progressRef.current[uid] + 1;
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            text: errorText,
            style: "error",
          },
        ]);
        handleError(e, instance);
      }
    });

    return saveRequestPromise;
  };
};
