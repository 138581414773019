import React, { useEffect, useContext, useRef } from "react";

export const NotificationContext = React.createContext(null);

let initialNotifications;
try {
  initialNotifications =
    JSON.parse(localStorage.getItem("notifications")) ?? [];
} catch {
  console.error("The notifications could not be parsed into JSON.");
  initialNotifications = [];
}

export function NotificationProvider(props) {
  const [notifications, setNotifications] =
    React.useState(initialNotifications);
  const notificationsRef = useRef(notifications);
  notificationsRef.current = notifications;

  useEffect(() => {
    return localStorage.setItem("notifications", JSON.stringify(notifications));
  }, [notifications]);

  const RemoveNotification = (id) => {
    if (notificationsRef.current.some((n) => n.id === id))
      setNotifications(notificationsRef.current.filter((i) => i.id !== id));
  };

  const contextValue = {
    notifications,
    setNotifications,
    RemoveNotification,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider. Wrap a parent component in <NotificationProvider> to fix this error."
    );
  }
  return context;
}
