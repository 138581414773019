import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "../../components/form-components";
import { requiredValidator, fieldValidator } from "../../components/validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../components/ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../components/ApiStatus";
import { PageLoader } from "../../components/PageLoader";
import { PageMutating } from "../../components/PageMutating";
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {  
  Hint  
} from "@progress/kendo-react-labels";
import { triggerEvents } from "../../services/triggerEventService";


const ValidatedInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};



const payloadGetter = getter("payload");

const triggerEventFormValidator = (values, isError, error) => {  
  // if(isError){
  //   var errors = error.response?.data;
  //   // combining errors related to same field
  //   var errorsMap = Object.entries(errors).map(([key, value]) => ({ [key]: value.join(', ')}));
  //   // converting array of object to one object with fields
  //   const errorReduce = errorsMap.reduce((k, v)=> Object.assign(k, v));
  //   var res = {
  //     VALIDATION_SUMMARY: "Please fix the following:",
  //     ...errorReduce
  //   };    
  //   //debugger
  //     return res;
  // }
  if (payloadGetter(values)) {
    return;
  }  
  return {
    VALIDATION_SUMMARY: "Please fill at least one of the following fields.",
    ["payload"]:
      "Please check the validation summary for more information.",    
  };
};

export const TrigerEvenForm = ({ trigerEvent, triggerEventMutation, isNew = false }) => {      

  const {isLoading } = triggerEventMutation; 
  const [type, setType] = React.useState(trigerEvent?.type?.id);

  const fieldChange = (e) =>
  {        
    //debugger;
    const fieldName = e.target.name;    
    if(fieldName === "type")
    setType(e.value.id);
    if(triggerEventMutation.error?.response?.data == null)        
    return;
    delete triggerEventMutation.error.response.data[fieldName];    
  }  
  
  return (
    <>        
    <Form
      onSubmit={(d) => 
        {          
          return triggerEventMutation.mutate( 
            {...d , 
              triggerAt: (DateTime.fromJSDate(d.triggerAt)).toISO(), 
              type: d?.type?.id,
              CanvasExamSubmissionFetchPayload: { 
                FilteredAssignmentIds : d?.CanvasExamSubmissionFetchPayload?.FilteredAssignmentIds.split(",")?.map(a => a?.trim()?.replaceAll('"', ''))
              }           
      })}}
      //onSubmit={(d) => console.log(d)}
      //validator={(v) => triggerEventFormValidator(v,triggerEventMutation.isError, triggerEventMutation.error) }
      initialValues={trigerEvent}                        
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          {/* <p> hi {data?.triggeredBy}</p> */}
          <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>
              Please fill in the following information:
            </legend>
            {triggerEventMutation.isError && <ValidationSummary error={triggerEventMutation.error} />}
            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </div>
              )}                                
             <div className="mb-3">
              <Field                
                name={"type"}
                label={"Program Name"}                               
                component={DropDownList}
                textField="text"
                dataItemKey="id"
                onChange={fieldChange}   
                disabled = {!isNew}                 
                data={triggerEvents}       
                validator={(d) => fieldValidator(d,"type", triggerEventMutation.error, requiredValidator)} 
              />              
            </div>           
            <div className="mb-3">
              <Field                
                name={"triggerAt"}
                label={"Trigger At"}
                hintDirection={"end"}                
                component={FormDatePicker}
                onChange={fieldChange}
                disabled = {!isNew}
                validator={(d) => fieldValidator(d,"triggerAt", triggerEventMutation.error)} 
                format="dd-MMM-yyyy HH:mm Z"
              />
            </div>
            {/* <div className="mb-3">
              <Field name={"payload"} component={ValidatedInput} 
              onChange={fieldChange}              
              validator={(d) => fieldValidator(d,"payload", triggerEventMutation.error, requiredValidator)} 
              label={"Pay Load"} />
            </div>           */}

            <div className="mb-3" hidden={type !== "CanvasExamSubmissionFetchTriggerEvent"}>
              <Field name={"CanvasExamSubmissionFetchPayload.FilteredAssignmentIds"} component={ValidatedInput} 
              onChange={fieldChange}              
              validator={(d) => type !== "CanvasExamSubmissionFetchTriggerEvent" ? null : fieldValidator(d,"CanvasExamSubmissionFetchPayload.FilteredAssignmentIds", triggerEventMutation.error, requiredValidator)}                            
              label={"Assignemtns Ids"} />
              <Hint style={{whiteSpace : "pre-wrap"}} > Example: "Law 261 LEC  2 - Final Exam", "Law 266 LEC  1 - Final Exam"</Hint>
            </div>  

            <div className="mb-3" hidden={type !== "TestTriggerEvent"}>
              <Field name={"TestPayload.TestNotes"} 
              component={ValidatedInput} 
              onChange={fieldChange}              
              validator={(d) => type !== "TestTriggerEvent" ? null : fieldValidator(d,"TestPayload.TestNotes", triggerEventMutation.error, requiredValidator)} 
              label={"Test Notes"} />
            </div>
            <div>Notes:</div>
            <div className="mb-3">
              <Field name={"notes"} 
              component={TextArea} 
              onChange={fieldChange}                     
              //validator={(d) => fieldValidator(d,"notes", triggerEventMutation.error, requiredValidator)} 
              label={" Notes"} />
            </div>                 
          </fieldset>
          <div className="k-form-buttons">
            <button
              type={"submit"}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              disabled={!formRenderProps.allowSubmit || isLoading}
            >              
              Submit
            </button>            
           <PageMutating/>
          </div>
        </FormElement>
      )}
    />
    </>
  );
};

