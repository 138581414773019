import React from "react";
import ApiStatus from "../components/ApiStatus";
import { useUserMyRoles } from "../services/userService";

export const MyAccount = (props) => {
  const { data, status, isSuccess, isError, error } = useUserMyRoles();

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;

  return (
    <div className="centered">
      <h4>My Account</h4>
      <p>
        <label>First Name:</label> {data.firstName}
        <br />
        <label>Last Name:</label> {data.lastName}
        <br />
        <label>Email:</label> {data.email}
        <br />
        <label>EmployeeId:</label> {data.employeeId}
        <br />
        <label>Roles:</label> {data.roles?.join(', ')}
        <br />
        <label>Claims:</label> {data.claims?.join(', ')}
      </p>
      <style>{`      
              label{
                font-weight: bold;
                margin-right: 10px;
              }              
              `}
        </style>
    </div>
  );
};
