import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useFetch } from "./useFetch";
import { usePut } from "./usePut";
import { usePost } from "./usePost";
import PropTypes from "prop-types";
import { useNotification } from "../components/notificationContext";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "./useDelete";

export const triggerEvents = [
  {
    id: "CanvasExamSubmissionFetchTriggerEvent",
    text: "Canvas Exam Submission Fetch",
  },
  { id: "TestTriggerEvent", text: "Test" },
];

export const useFetchTriggerEvents = (query) => {
  //debugger;
  return useFetch({
    url: `TriggerEvent`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return { ...r, triggerAt: new Date(r.triggerAt) };
        }),
      };
    },
    query: query,
  });
};

export const useFetchTriggerEvent = (id) => {
  return useFetch({
    url: `TriggerEvent/${id}`,
    dataMapper: (d) => {
      return {
        ...d,
        triggerAt: new Date(d.triggerAt),
        type: {
          id: d.type,
          text: triggerEvents.find((i) => i.id === d.type).text,
        },
        CanvasExamSubmissionFetchPayload: {
          FilteredAssignmentIds:
            d?.canvasExamSubmissionFetchPayload?.filteredAssignmentIds
              ?.map((a) => `"${a}"`)
              ?.join(","),
        },
      };
    },
  });
};

export const useUpdateTriggerEvent = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return usePut(`TriggerEvent/{id}`, {
    onSuccess: (_, triggerEvent) => {
      queryClient.invalidateQueries([`TriggerEvent/${triggerEvent.id}`]);
      nav(`/triggerevents?sort=triggerAt-desc&page=1&pageSize=10`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useDeleteTriggerEvent = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`TriggerEvent`, {
    onSuccess: (_, triggerEvent) => {
      queryClient.invalidateQueries([`TriggerEvent/${triggerEvent.id}`]);
      queryClient.invalidateQueries(["TriggerEvent"]);
      nav(`/triggerevents?sort=triggerAt-desc&page=1&pageSize=10`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useCreateTriggerEvent = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return usePost(`TriggerEvent/{id}`, {
    onSuccess: (_, triggerEvent) => {
      //queryClient.invalidateQueries([`TriggerEvent/${triggerEvent.id}`]);
      nav(`/triggerevents?sort=triggerAt-desc&page=1&pageSize=10`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: "Your data has been saved.",
        },
      ]);
      //debugger;
      //console.log(triggerEvent);
      return _;
    },
    onprogress: (_, triggerEvent) => {},
  });
};
