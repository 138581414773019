import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { useNotification } from './notificationContext';
import { v4 as uuidv4 } from "uuid";
import { useEffect } from 'react';
import { useState } from 'react';

const NotificationComponent = (props) => {  
  const {notifications , setNotifications, RemoveNotification} = useNotification();  

  const handleClick = () => {            
    setNotifications([...notifications, 
      {
        id : uuidv4(),
        style : 'error',
        text : 'test',        
      }
    ])
  };

  return <React.Fragment>
      {/* <Button icon="layout" fillMode="flat" onClick={handleClick} />                 */}
      <NotificationGroup style={{
      right: 0,
      bottom: 0,
      alignItems: 'flex-start',
      flexWrap: 'wrap-reverse'
    }}>
          {notifications.map(item => {
          return <div key={item.id}>
           { 
           <NotificiationContainer id={item.id} onDelete= {(id) => RemoveNotification(id)} style={item.style} text = {item.text} />
          }
        </div>                              
      })}
      </NotificationGroup>
    </React.Fragment>;
};

const NotificiationContainer = (props)=>{   
   useEffect(() =>{          
      setTimeout(() =>{                        
        props.onDelete(props.id);
      }, 5000);  
    }, [])

  return (<div key={props.id}>
           { <Fade appear={true} enter={true} exit={true} transitionEnterDuration={500} transitionExitDuration={1000}>
            <Notification type={{ style: props.style, icon: true }} closable={true} onClose={() => props.onDelete(props.id)
              } style={{
                overflow: 'visible'
              }}
              >
            <span>{props.text}</span>
          </Notification></Fade>
          }
        </div>) 
}


export default NotificationComponent;