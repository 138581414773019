import { usePostPublic } from "./usePost";

export const useSignupSubmit = () => {
  return usePostPublic(`signUp/submit`, {
    onSuccess: (_, data) => {
      window.location.assign(`https://passwordreset.microsoftonline.com/passwordreset?username=${data.lawEmail}#!/`, );
    },
    onprogress: (_, data) => {},
  });
};
