import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useFetch, useFetchWithPost } from "./useFetch";
import { usePut } from "./usePut";
import { usePost } from "./usePost";
import PropTypes from "prop-types";
import { useNotification } from "../components/notificationContext";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "./useDelete";
import { redirect } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "axios";
import { recordsApiConfig } from "../authConfig";
import { handleError } from "./apiUtils";

export const triggerEvents = [
  {
    id: "CanvasExamSubmissionFetchTriggerEvent",
    text: "Canvas Exam Submission Fetch",
  },
  { id: "TestTriggerEvent", text: "Test" },
];

export const useFetchEnrollmentSpreadsheet = () => {
  return useFetch({
    url: `spreadsheet/enrollmentspreadsheet`,
    dataMapper: (d) => {
      // return {
      //   ...d,
      //   availabilityStarts: new Date(d.availabilityStarts),
      //   availabilityEnds: new Date(d.availabilityEnds),
      // };
      return d;
    },
  });
};

export const useUpdateSpreadsheet = (id) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`spreadsheet/${id}`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries([`spreadsheet/${id}`]);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useFetchProceessedFile = (id) => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();

  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };
  return async (data, activeSheet, fileName) => {
    try {
      const { accessToken } = await instance.acquireTokenSilent(
        accessTokenRequest
      );

      const response = await axios.put(
        `${process.env.REACT_APP_RECORD_API_URL}${"spreadsheet/"}${id}`,
        data,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      queryClient.invalidateQueries([`spreadsheet/${id}`]);

      const resp = await axios.get(
        `${
          process.env.REACT_APP_RECORD_API_URL
        }${"spreadsheet/"}${id}/${activeSheet}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob", // important
        }
      );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      //fileDownload(resp.data.fileContents, resp.data.fileDownloadName);
    } catch (e) {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest);
      }
      //debugger;
      var errorText = e?.message;
      if (e?.message === "Network Error")
        errorText = `Network Error, can't connect.`;
      else if (e?.response?.status === 403) {
        errorText =
          "Forbidden access. You don't have permission to see this item.";
      }
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          text: errorText,
          style: "error",
        },
      ]);
      handleError(e, instance);
    } finally {
      // setLoading(false);?
    }
  };
};

export const useFetchProceessedFile2 = (id) => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };
  return async (activeSheet, fileName) => {
    try {
      const { accessToken } = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      const resp = await axios.get(
        `${
          process.env.REACT_APP_RECORD_API_URL
        }${"spreadsheet/"}${id}/${activeSheet}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob", // important
        }
      );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      //fileDownload(resp.data.fileContents, resp.data.fileDownloadName);
    } catch (e) {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest);
      }
      //debugger;
      var errorText = e?.message;
      if (e?.message === "Network Error")
        errorText = `Network Error, can't connect.`;
      else if (e?.response?.status === 403) {
        errorText =
          "Forbidden access. You don't have permission to see this item.";
      }
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          text: errorText,
          style: "error",
        },
      ]);
      handleError(e, instance);
    } finally {
      // setLoading(false);?
    }
  };
};
