import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useFetch, useFetchWithPost } from "./useFetch";
import { usePut } from "./usePut";
import { usePost } from "./usePost";
import PropTypes from "prop-types";
import { useNotification } from "../components/notificationContext";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "./useDelete";
import { redirect } from "react-router-dom";

export const useRecaptchaTest = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return usePost(`recaptcha`, {
    onSuccess: (_, data) => {
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: "Your data has been saved.",
        },
      ]);
      return _;
    },
    onprogress: (_, data) => {},
  });
};
