import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtoolsPanel } from "@tanstack/react-query-devtools";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import Elmahio from "elmah.io.javascript";
import { emailValidator } from "./components/validators";
import { NotificationProvider } from "./components/notificationContext";
import ReactGA from "react-ga4";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// new Elmahio({
//   apiKey: process.env.REACT_APP_ELMAHIO_API_KEY,
//   logId: process.env.REACT_APP_ELMAHIO_LOG_ID,
// });

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
//Initialize GA4
process.env?.REACT_APP_GOOGLE_ANALYTICS &&
  ReactGA.initialize(process.env?.REACT_APP_GOOGLE_ANALYTICS);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // https://tanstack.com/query/v4/docs/guides/window-focus-refetching
    },
  },
});

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <NotificationProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env?.REACT_APP_RECAPTCHA_KEY}
          >
            <App />
          </GoogleReCaptchaProvider>
        </NotificationProvider>
        {/* <ReactQueryDevtoolsPanel
          isOpen={process.env?.REACT_APP_ENVIRONMENT === "Dev"}
          className={
            process.env?.REACT_APP_ENVIRONMENT !== "Dev" ? "hidden" : ""
          }
        /> */}
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
