import { useFetchWithPost } from "./useFetch";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../components/notificationContext";
import { useQueryClient } from "@tanstack/react-query";
import { usePut } from "./usePut";
import { useFetch } from "./useFetch";

// for security reasons, we use post verb instead of get here. (useFetchWithPost over useFetch)
// even though we just get info.
// this changes app behavior, so on every page refresh it makes the api call vs every login
// note this is also because of slatetime: Infinity which keeps the data in cache forever.
export const useUserMyRoles = () => {
  return useFetchWithPost(
    {
      url: `user/me`,
    },
    { staleTime: Infinity }
  );
};

export const useRunImportUsers = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return usePut(`user/importusers`, {
    onSuccess: (_, data) => {
      nav(`/users`);
      return _;
    },
    onprogress: (_, scholarship) => {},
  });
};

export const useFetchUsers = (query) => {
  return useFetch({
    url: `users`,
    dataMapper: (d) => {
      return {
        ...d,
        // data: d.data.map((r) => {
        //   return { ...r, submittedAt: new Date(r.submittedAt) };
        // }),
      };
    },
    query: query,
  });
};
