import { Link } from "react-router-dom";

export function ForbiddenAccess() {
  return (
    <div>
      <h2>Forbidden Access! (403 Error)</h2>
      <p>
        You don't have access.
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
