export async function handleResponse(response) {
  //debugger;
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

export function replaceAttributesInUrl(url, obj) {
  // Find all attribute placeholders in the URL
  const attributePlaceholders = url.match(/\{.*?\}/g);

  // Replace each attribute placeholder with its corresponding value in the object
  let newUrl = url;
  attributePlaceholders?.forEach((placeholder) => {
    const attributePath = placeholder.slice(1, -1).split(".");
    let attributeValue = obj;
    attributePath.forEach((attributeName) => {
      attributeValue = attributeValue[attributeName];
    });
    newUrl = newUrl.replace(placeholder, attributeValue);
  });

  return newUrl;
}
