import {
    useLocation,
    useNavigate,
    useParams
  } from "react-router-dom";
  
export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation(); // removed location prop
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}
