import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useFetchSetting, useUpdateSetting } from "../../services/settingsService";
import { useParams } from "react-router-dom";
import { CanvasExamSubmissionFetchSettingForm } from "./CanvasExamSubmissionFetchSettingForm";
import ApiStatus from "../../components/ApiStatus";

export const CanvasExamSubmissionFetchSettingFormEdit = ({settingId}) => {  
  //const settingId = 'RecordsWebsiteSetting'
  const { data, status, isSuccess, isError, error } = useFetchSetting(settingId);
  const updateMutation = useUpdateSetting(settingId);

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;

  return (
    <>      
      <CanvasExamSubmissionFetchSettingForm
        data={data}        
        eventMutation = {updateMutation}                
      />
    </>
  );
};