import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "../../components/form-components";
import { requiredValidator, fieldValidator } from "../../components/validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../components/ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../components/ApiStatus";
import { PageLoader } from "../../components/PageLoader";
import { PageMutating } from "../../components/PageMutating";
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {  
  Hint  
} from "@progress/kendo-react-labels";
import { triggerEvents } from "../../services/triggerEventService";
import { useParams } from "react-router-dom";
import { Label } from "@progress/kendo-react-labels";
import {useFetchScholarship} from "../../services/scholarshipService";
import { Link } from "react-router-dom";

export const ScholarshipView = (props) => {      
  const { id } = useParams();
  if (!id) throw Error("Scholarship id not found");

  const { data, status, isSuccess, isError, error } = useFetchScholarship(id);  

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;

  return (
    <>     
    <div>
        <label>Id</label>   
        <p>{data.id} </p>

        <label>highSchoolCityState</label>   
        <p>{data.highSchoolCityState} </p>

        <label>undergradSchoolDegreeMajor</label>   
        <p>{data.undergradSchoolDegreeMajor} </p>

        <label>expectedGraduationYear</label>   
        <p>{data.expectedGraduationYear.text} </p>

        <label>dateOfBirth</label>   
        <p>{ data.dateOfBirth?.toLocaleString()} </p>                    
       
        <label>Created By</label>   
        <p>{data.createdBy} </p>

        <label>Created At</label>   
        <p>{data.createdAt} </p>                            

        <Link to={'/scholarships'}>Back</Link>

      </div>
      <style>{`              
              label {
                font-weight:bold;
              }             
              `}
        </style>
    </>
  );
};

