import { useFetch, useFetchWithPost } from "./useFetch";

// for security reasons, we use post verb instead of get here. (useFetchWithPost over useFetch)
// even though we just get info.
// this changes app behavior, so on every page refresh it makes the api call vs every login
// note this is also because of slatetime: Infinity which keeps the data in cache forever.
export const useFetchGroups = () => {
  return useFetch(
    {
      url: `groups`,
    },
    { staleTime: Infinity }
  );
};
