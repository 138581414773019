import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useRunImportUsers } from "../../services/userService";
import ApiStatus from "../../components/ApiStatus";
import { ImportUsersForm } from "./ImportUsersForm";
import { useFetchGroups } from "../../services/groupService";

export const ImportUsersPage = (props) => {
  const { data, status, isSuccess, isError, error } = useFetchGroups();
  const runImportUsersEventMutation = useRunImportUsers();

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;

  return (
    <>      
      <ImportUsersForm
        data={data}        
        eventMutation = {runImportUsersEventMutation}                
      />
    </>
  );
};