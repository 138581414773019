import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "../../components/form-components";
import { requiredValidator, fieldValidator } from "../../components/validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../components/ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../components/ApiStatus";
import { PageLoader } from "../../components/PageLoader";
import { PageMutating } from "../../components/PageMutating";
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {  
  Hint  
} from "@progress/kendo-react-labels";
import { triggerEvents } from "../../services/triggerEventService";


export const FirstYearPreferencesSettingForm = ({ data, eventMutation, isNew = false }) => {      

  const {isLoading } = eventMutation; 
  const [type, setType] = React.useState(data?.type?.id);  

  const fieldChange = (e) =>
  {        
    debugger;
    const fieldName = e.target.name;    
    if(fieldName === "type")
    setType(e.value.id);
    if(eventMutation.error?.response?.data == null)        
    return;
    delete eventMutation.error.response.data[fieldName];    
  }  
  
  return (
    <>        
    <Form
      onSubmit={(d) => 
        {
          return eventMutation.mutate(
            {...d , 
              availabilityStarts: (DateTime.fromJSDate(d.availabilityStarts)).toISO(), 
              availabilityEnds: (DateTime.fromJSDate(d.availabilityEnds)).toISO(), 
            })
        }}      
      initialValues={data}                        
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          {/* <p> hi {data?.triggeredBy}</p> */}
          <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>
              Please fill in the following information:
            </legend>
            {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </div>
              )}                                                                             
            <div className="mb-3">
              <Field name={"currentTermId"} 
              component={Input} 
              onChange={fieldChange}                     
              //validator={(d) => fieldValidator(d,"notes", triggerEventMutation.error, requiredValidator)} 
              label={"Current Term Id"} />
            </div>  
            <div className="mb-3">
            <Field                
                name={"availabilityStarts"}
                label={"Availability Starts"}
                hintDirection={"end"}                
                component={FormDatePicker}
                onChange={fieldChange}
                //disabled = {!isNew}
                //validator={(d) => fieldValidator(d,"triggerAt", triggerEventMutation.error)} 
                format="dd-MMM-yyyy HH:mm Z"
              />
            </div>      
            <div className="mb-3">
            <Field                
                name={"availabilityEnds"}
                label={"Availability Ends"}
                hintDirection={"end"}                
                component={FormDatePicker}
                onChange={fieldChange}
                //disabled = {!isNew}
                //validator={(d) => fieldValidator(d,"triggerAt", triggerEventMutation.error)} 
                format="dd-MMM-yyyy HH:mm Z"
              />
            </div>               
            <div>Notes:</div>
            <div className="mb-3">
              <Field name={"notes"} 
              component={TextArea} 
              onChange={fieldChange}                     
              //validator={(d) => fieldValidator(d,"notes", triggerEventMutation.error, requiredValidator)} 
              label={" Notes"} />
            </div>                           
          </fieldset>
          <div className="k-form-buttons">
            <button
              type={"submit"}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              disabled={!formRenderProps.allowSubmit || isLoading}
            >              
              Submit
            </button>            
           <PageMutating/>
          </div>
        </FormElement>
      )}
    />
    </>
  );
};

