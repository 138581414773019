import React, { Component, useState, useEffect } from "react";
import * as ReactDOM from "react-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { emailValidator } from "../../components/validators";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { useSignupSubmit } from "../../services/signUpService";
import { Error } from "@progress/kendo-react-labels";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { PageMutating } from "../../components/PageMutating";
import { parsePhoneNumber } from "react-phone-number-input";
import ValidationSummary from "../../components/ValidationSummary";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import "../../components/styles.css";
import { useParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";

const EmailInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const SignUp = ({ data }) => {
  useEffect(() => {
    document.title = "LawNET - Account Activation";
  }, []);
  const {lawEmailPreload, primaryEmailPreload} = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const eventMutation = useSignupSubmit();
  const { isLoading } = eventMutation;
  const isMutating = useIsMutating();


  const [preload] = React.useState({
    lawEmail: lawEmailPreload ?? "",
    alternateEmail: primaryEmailPreload ?? ""
  });
  
  let [formState, setFormState] = React.useState(data);

  const [phone, setPhone] = React.useState("");
  const [lawEmail, setLawEmail] = React.useState(preload.lawEmail ?? "");
  
  const [nationalNumber, setNationalNumber] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);

  const hasAlreadySubmitted = (e) => {
    if (e === undefined) return false;
    if (e === "") return false;
    if (e === null) return false;

    const errors = e.response?.data?.errors ?? e.response?.data;
    const errorValues = Object.entries(errors).map(([key, value]) => value);
    let matched = false;
    errorValues.forEach(element => {
      if((""+element).includes("User has already submitted")) 
      {
        matched = true;
      }
    });

    return matched;
  };

  const isLawNetEmailValid = (lawEmail) => {
    return lawEmail === undefined || lawEmail === "" || lawEmail?.trim()?.toLowerCase()?.endsWith("@lawnet.ucla.edu")
  }
  const updatePhone = (e) => {
    if (e === undefined) return;
    if (e === "") return;
    if (!isPossiblePhoneNumber(e)){
      setPhone(e);
      return;
    }

    const parsedPhoneNumber = parsePhoneNumber(e);
    setCountry(parsedPhoneNumber.countryCallingCode);
    setNationalNumber(parsedPhoneNumber.nationalNumber);
    setPhone(e);
  };
  
  const handleReCaptchaVerify = React.useCallback(
    async (d) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      eventMutation.mutate({
        ...d,
        ReCaptchaResponseCode: await executeRecaptcha("signUp/submit"),
      });
    },
    [executeRecaptcha]
  );

  return (
    <>
    <Form
      initialValues={formState}
      onSubmit={(d) =>
        handleReCaptchaVerify({
          ...d,
          nationalNumber: nationalNumber ?? "",
          countryCallingCode: country ?? "",
        })
      }
      render={(formRenderProps) => (
        <FormElement>
          <h2>Activate your LawNET Account</h2>
          This form will activate your LawNET account. After you complete this
          information, and agree to the terms, you will be redirected to
          Microsoft to finish the sign-up process.
          <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>
              Please validate your information
            </legend>
            {eventMutation.isError && (
              <div><ValidationSummary error={eventMutation.error} /></div>
            )}
            { eventMutation.isError && formRenderProps.errors && hasAlreadySubmitted(eventMutation.error ) && <div className={"k-messagebox k-messagebox-info alert-link"}>
              <div>
                <span className="k-icon k-i-info"></span>
                {"   "}You have already submitted. If you need to set your
                password, please visit{" "}
                <a
                  target="_"
                  href={`https://passwordreset.microsoftonline.com/passwordreset?username=${eventMutation?.variables?.lawEmail}`}
                >
                  https://passwordreset.microsoftonline.com/passwordreset
                </a>
                .
              </div>
            </div>}
            <div className="mb-3">
              <div className="k-label">
                LawNET Email Address (as provided in your admission
                notification):
              </div>
              <Field
                name={"lawEmail"}
                type={"email"}
                //value={lawEmail}
                component={EmailInput}
                onChange={(e) => setLawEmail(e.target.value) }
                placeholder={"yourLawEmail@lawnet.ucla.edu"}
                validator={emailValidator}
                hint={"Hint: Your @lawnet.ucla.edu Law Email Address"}
                disabled={isLoading}
              />
               {
                !isLawNetEmailValid(lawEmail) &&
                <Error>Invalid Email Domain. Please ensure that the domain part (e.g., "@lawnet.ucla.edu") is correct.</Error>
                }
            </div>
            <div className="mb-3">
              <div className="k-label">
                Primary Email Address (as indicated on your application):
              </div>
              <Field
                name={"alternateEmail"}
                type={"email"}                
                component={EmailInput}
                placeholder={"yourEmail@domain.com"}
                validator={emailValidator}
                hint={"Hint: Your Alternate Email Address"}
                disabled={isLoading}
              />
            </div>
            <div className="mb-3">
              <div className="k-label">
                Current Phone Number (with country code):
              </div>
              <PhoneInput
                label={"phone"}
                placeholder="+1 123 456 7890"
                //value={phone}
                onChange={updatePhone}
                withCountryCallingCode={true}
                international={true}
                rules={{ required: true, validate: isPossiblePhoneNumber }}
                disabled={isLoading}
              />
              {phone !== undefined && phone !== "" && !isPossiblePhoneNumber(phone) && 
                  <Error>Please insert a valid phone number</Error>                
              }
            </div>
            <div className="mb-3">
              <Checkbox
                name={"terms"}
                label={
                  "I agree that the above information is correct and I agree that this contact information may be stored in the event I need to reach my account in the future."
                }
                required={true}
                onChange={(e) => setIsChecked(e.value)}
                disabled={isLoading}
              />
            </div>
          </fieldset>
          <div>
            <Button
              type={"submit"}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              disabled={
                !formRenderProps.allowSubmit 
                || !isPossiblePhoneNumber(phone) ||
                !isLawNetEmailValid(lawEmail) ||
                isChecked === false ||
                isLoading
              }
              themeColor={"primary"}
            >
              Continue Account Setup
            </Button>
            <PageMutating />
            {isMutating ? 
            <div>
            <br/>
              <div className={"k-messagebox k-messagebox-warning alert-link"}>
                <div>
                  <span className="k-icon k-i-info"></span>
                  <span>{" "}Submitting. Please wait, this may take some time. You will be redirected upon success.</span>
                </div>
              </div>
            </div> : ""}
          </div>
          <br />
          <div className="k-label">
            <small>
              <p>
                For help, please visit{" "}
                <a target="_blank" href="https://help.law.ucla.edu">
                  https://help.law.ucla.edu
                </a>
                .
              </p>
              <p>
                Additional details regarding your email account and other
                technology services offered by the School of Law will be
                provided at Orientation in August.
              </p>
              <p>
                If you have issues, please contact your program administrator.
              </p>
            </small>
          </div>
          <br />          
          <br />
          <br />
          {formRenderProps.valueGetter("lawEmail") === undefined && preload.lawEmail.length > 0 &&
          (
            formRenderProps.onChange("lawEmail", { value: preload.lawEmail})
          )}
          {formRenderProps.valueGetter("alternateEmail") === undefined && preload.alternateEmail.length > 0 &&
          (
            formRenderProps.onChange("alternateEmail", { value: preload.alternateEmail})
          )}        
        </FormElement>
      )}
    />
    </>
  );
};
