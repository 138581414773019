import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "../../components/form-components";
import { requiredValidator, fieldValidator } from "../../components/validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../components/ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../components/ApiStatus";
import { PageLoader } from "../../components/PageLoader";
import { PageMutating } from "../../components/PageMutating";
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {  
  Hint  
} from "@progress/kendo-react-labels";
import { triggerEvents } from "../../services/triggerEventService";
import { useParams } from "react-router-dom";
import {
  useFetchTriggerEvent,  
} from "../../services/triggerEventService";
import { Label } from "@progress/kendo-react-labels";
import { useNavigate } from "react-router-dom";
export const TrigerEventView = (props) => {      
  const { id } = useParams();
  if (!id) throw Error("Trigger Event id not found");
  const nav = useNavigate();
  const { data, status, isSuccess, isError, error } = useFetchTriggerEvent(id);  

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;
console.log(data);

  

  return (
    <>         
         <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          
          onClick={() =>  nav(`edit`) }
        >
          <span className="k-icon k-i-edit"></span>  
          Edit
        </button>
        <hr />
    <div>               
        <label>Program Type</label>   
        <p>{data.type.text} </p>
        <label>Created By</label>   
        <p>{data.createdBy} </p>
        <label>Created At</label>   
        <p>{data.createdAt} </p>
        <label>Trigger At</label>   
        <p>{data.triggerAt.toLocaleString()} </p>
        {
          data.type.id === "TestTriggerEvent" && 
            <>
              <label>Test Payload</label>   
              <pre><code>{data?.testPayload?.testNotes}</code></pre>
            </>            
        }

        {
          data.type.id  === "CanvasExamSubmissionFetchTriggerEvent" && 
            <>
              <label>FilteredAssignmentIds</label>                                 
                {                  
                  data?.canvasExamSubmissionFetchPayload?.filteredAssignmentIds.map((id, index) => {
                    return <div key={index}>"{id}"</div>
                  })              
                }                
            </>            
        }

            

        <label>Notes </label>   
        <p>{data.notes} </p>
        <label>Status</label>   
        <p>{data.status} </p>
        <label>Errors</label> 
         <code>{data.errors}</code>     

        

      </div>
      <style>{`              
              label {
                font-weight:bold;
              }             
              `}
        </style>
    </>
  );
};