import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import {
  useFetchTriggerEvent,
  useUpdateTriggerEvent,
} from "../../services/triggerEventService";
import { useParams } from "react-router-dom";
import { TrigerEvenForm } from "./TrigerEventForm";
import ApiStatus from "../../components/ApiStatus";

export const TrigerEventEdit = (props) => {
  const { id } = useParams();
  if (!id) throw Error("Trigger Event id not found");

  const { data, status, isSuccess, isError, error } = useFetchTriggerEvent(id);
  const updateTriggerEventMutation = useUpdateTriggerEvent();

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;

  return (
    <>      
      <TrigerEvenForm
        trigerEvent={data}        
        triggerEventMutation = {updateTriggerEventMutation}                
      />
    </>
  );
};

// TrigerEventEdit.propTypes = {
//   id: PropTypes.string.isRequired,
//   data: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     triggeredBy: PropTypes.object.isRequired,
//   }).isRequired,
// };

// TrigerEventEdit.propTypes = {
//   triggeredBy: PropTypes.func.isRequired,
//   courses: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       title: PropTypes.string.isRequired,
//       authorId: PropTypes.number.isRequired,
//       category: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };
