import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormDatePicker } from "../components/form-components";
import { PageMutating } from "../components/PageMutating";

import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
  } from 'react-google-recaptcha-v3';
  
  import {useRecaptchaTest} from "../services/recaptchaService";
  import ValidationSummary from "../components/ValidationSummary";
  
  export const ReCaptchaForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const eventMutation = useRecaptchaTest();
    const {isLoading } = eventMutation; 
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = React.useCallback(async (d) => {
        //debugger
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      
      eventMutation.mutate({...d, ReCaptchaResponseCode: await executeRecaptcha('yourAction')});
      //const token = await executeRecaptcha('yourAction');
      // Do whatever you want with the token
      }, [executeRecaptcha]);
  
    // You can use useEffect to trigger the verification as soon as the component being loaded
    // React.useEffect(() => {
    //   handleReCaptchaVerify();
    // }, [handleReCaptchaVerify]);
    
    // <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
    return (
        <>        
        <Form          
          onSubmit={handleReCaptchaVerify}          
          //initialValues={}                        
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 650,
              }}
            >
              {/* <p> hi {data?.triggeredBy}</p> */}
              <fieldset className={"k-form-fieldset"}>
                <legend className={"k-form-legend"}>
                  Please fill in the following information:
                </legend>
                {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
                {formRenderProps.visited &&
                  formRenderProps.errors &&
                  formRenderProps.errors.VALIDATION_SUMMARY && (
                    <div className={"k-messagebox k-messagebox-error"}>
                      {formRenderProps.errors.VALIDATION_SUMMARY}
                    </div>
                  )}                                
                          
                <div className="mb-3">
                  <Field                
                    name={"Name"}
                    label={"Name"}                    
                    component={FormInput}                                                            
                  />
                </div>                
              </fieldset>
              <div className="k-form-buttons">
                <button
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  disabled={!formRenderProps.allowSubmit || isLoading}
                >              
                  Submit
                </button>            
               <PageMutating/>
              </div>
            </FormElement>
          )}
        />
        </>
      );
  };