import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "../../components/form-components";
import { requiredValidator, fieldValidator } from "../../components/validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../components/ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../components/ApiStatus";
import { PageLoader } from "../../components/PageLoader";
import { PageMutating } from "../../components/PageMutating";
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {  
  Hint  
} from "@progress/kendo-react-labels";
import { triggerEvents } from "../../services/triggerEventService";
import {
  ListView,
  ListViewHeader,
  ListViewFooter,
} from "@progress/kendo-react-listview";

import { useParams } from "react-router-dom";
import {
  useFetchTriggerEvent,  
} from "../../services/triggerEventService";
import { Label } from "@progress/kendo-react-labels";
import { useNavigate } from "react-router-dom";
import { useFirstYearPreference } from "../../services/firstYearPreferenceService";


export const FirstYearPreferenceView = (props) => {      
  const { id } = useParams();
  if (!id) throw Error("Trigger Event id not found");
  const nav = useNavigate();
  const { data, status, isSuccess, isError, error } = useFirstYearPreference(id);  

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;  

  return (
    <>         
         <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          
          onClick={() =>  nav(`edit&step=0`) }
        >
          <span className="k-icon k-i-edit"></span>  
          Edit
        </button>
        <hr />
    <div>                               
        <div>
          <label>Student Fist Name</label>   
          <p>{data.createdByUser.firstName} </p>
          <label>Student LastName</label>   
          <p>{data.createdByUser.lastName} </p>
          <label>Student Id</label>   
          <p>{data.createdByUser.employeeId} </p>                
        </div>
        
        <div className="col">
          <div>
            <label>ElectivePreferences Preferences</label>   
            <ListView
              data={data.electivePreferences}
              item={CourseItem}
              header={ListViewHeader}
              footer={ListViewFooter}
              style={{
                width: "100%",}
              }
            />
          </div>
          

          <label>CriminalLawPreferences Preferences</label>   
          <ListView
            data={data.criminalLawPreferences}
            item={CourseItem}
            header={ListViewHeader}
            footer={ListViewFooter}
            style={{
              width: "100%",}
            }
          />

          <label>Property Law Preferences</label>   
          <ListView
            data={data.propertyLawPreferences}
            item={CourseItem}
            header={ListViewHeader}
            footer={ListViewFooter}
            style={{
              width: "100%",}
            }
          />
        </div>
        
        {/* <li className="">
        {          
          data.electivePreferences.map(course => <CourseItem key={course.id} {...course} />)                    
        }
        </li>

      <label>CriminalLawPreferences Preferences</label>   
        {
          data.criminalLawPreferences.map(course => <CourseItem key={course.id} {...course} />)
        }

      <label>PropertyLawPreferences Preferences</label>   
        {
          data.propertyLawPreferences.map(course => <CourseItem key={course.id} {...course} />)
        } */}

    </div>
      <style>{`              
              label {
                font-weight:bold;
                margin-top: 35px;
              }             
              `}
        </style>
    </>
  );
};


const CourseItem = (props) => {
  let { id, category, courseNum, section, order, title, instructorLastName,instructorFirstName,units,capacity } = props.dataItem;
  console.log(props);
  return (
    <div id="id" className="k-listview-item p-2 border-bottom align-middle">
      <div style={{ width: 20 }}>
        <span> {order} </span>
      </div>
      <div>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {courseNum}, {section}.
        </span>
        <br />
        {title}
        <br />        
        <span className="font-italic">with Prof. {instructorLastName} {instructorFirstName}</span> <span> ({units} units)</span>, <span>{capacity} capacity</span>
      </div>
    </div>
  );
};
