// import { user } from "../utils/User";
import { handleError } from "./apiUtils";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useNotification } from "../components/notificationContext";
import { useMsal } from "@azure/msal-react";
import { recordsApiConfig } from "../authConfig";
import { v4 as uuidv4 } from "uuid";
import { replaceAttributesInUrl } from "./apiUtils";

export const usePut = (tokenizedURL, ...args) => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };
  return useMutation(async (data) => {
    try {
      const { accessToken } = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      const url = replaceAttributesInUrl(tokenizedURL, data);
      //debugger;
      return await axios.put(
        //`${process.env.REACT_APP_RECORD_API_URL}${tokenizeURL}/${data?.id}`,
        `${process.env.REACT_APP_RECORD_API_URL}${url}`,
        data,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
    } catch (error) {
      // Catch interaction_required errors and call interactive method to resolve
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest);
      }

      if (error?.response?.status === 403) {
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            style: "error",
            text: "Forbidden access. You don't have permission to perform this action.",
          },
        ]);
      }
      handleError(error, instance);
    }
  }, ...args);
};

export const usePutWithoutId = (url, ...args) => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };
  return useMutation(async (data) => {
    try {
      const { accessToken } = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      return await axios.put(
        `${process.env.REACT_APP_RECORD_API_URL}${url}`,
        data,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
    } catch (error) {
      // Catch interaction_required errors and call interactive method to resolve
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest);
      }

      if (error?.response?.status === 403) {
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            style: "error",
            text: "Forbidden access. You don't have permission to perform this action.",
          },
        ]);
      }
      handleError(error, instance);
    }
  }, ...args);
};
