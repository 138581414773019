import React from "react";
import ApiStatus from "../../components/ApiStatus";
import { useFetchVersion } from "../../services/versionService";

export const Version = (props) => {
  const { data, status, isSuccess, isError, error } = useFetchVersion();

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;

  return (
    <div className="centered">
      <h4>App Info</h4>
      <p>
        Name : {process.env.REACT_APP_NAME}
        <br />
        Version: {process.env.REACT_APP_VERSION}
        <br />
        <br />
      </p>
      <h4> API Info</h4>
      <p>
        Api Id: {data.id} <br />
        Api Name: {data.name} <br />
        Api Enviorment: {data.enviorment} <br />
        Api Version: {data.version} <br />
      </p>
    </div>
  );
};
