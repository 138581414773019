import { PageLoader } from "./PageLoader";

const ApiStatus = ({ status }) => {
  //console.log(status);
  switch (status) {
    case "error":
      return <div>Error communicating with the backend</div>;
    case "idle":
      return <div>Idle</div>;
    case "loading":
      return "";
    default:
      throw Error("Unknown API state");
  }
};

export default ApiStatus;
