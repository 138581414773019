import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import {
  FormInput,
  FormUpload,
  FormDatePicker,
} from "../../components/form-components";
import {
  userNameValidator,
  emailValidator,
  fieldValidator,
  passwordValidator,
} from "../../components/validators";
import { Upload } from "@progress/kendo-react-upload";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useFetchFile } from "../../services/uploadService";
const fileStatuses = [
  "UploadFailed",
  "Initial",
  "Selected",
  "Uploading",
  "Uploaded",
  "RemoveFailed",
  "Removing",
];

const CustomListItemUI = (props) => {
  const fetchFile = useFetchFile();
  return (
    <>
      <ul>
        {props.files.map((file) => (
          <li key={file.name}>
            <button
              download
              onClick={(e) => {
                e.preventDefault();
                fetchFile(file.uid, file.name);
              }}
            >
              {file.name}
            </button>
            {/* <a href={`https://localhost:7228/api/file/${file.uid}`}></a> */}
          </li>
        ))}
      </ul>
      {props.children}
    </>
  );
};

export const expectedGraduationYearsOptions = () => {
  var startYear = new Date().getFullYear() - 5;
  return [...Array(15).keys()].map((i) => ({
    id: i + startYear,
    text: i + startYear,
  }));
};
const onBeforeUpload = (event) => {
  //debugger;
  event.additionalData.description = "File upload";
};
const onBeforeRemove = (event) => {
  event.additionalData.description = "File remove";
};
const onAdd = (event) => {
  console.log("add");
  //debugger;
  //event.additionalData.description = "File Add";
};

const onStatusChange = (event) => {
  // debugger;
  console.log("onStatusChange");
  console.log(event);
  //setFiles()
  //debugger;
  //event.additionalData.description = "File Add";
};

// const onSaveRequest = (files, options, onProgress) => {
//   const uid = files[0].uid;

//   progressRef.current[uid] = 0;

//   // Simulate save request
//   const saveRequestPromise = new Promise((resolve, reject) => {
//       requestIntervalRef.current[uid] = setInterval(
//           () => {
//               onProgress(uid, {loaded: progressRef.current[uid], total: 100});

//               if (progressRef.current[uid] === 100) {
//                   resolve({ uid: uid });

//                   delete progressRef.current[uid];
//                   clearInterval(requestIntervalRef.current[uid]);
//               } else {
//                   reject({ uid: uid })
//                   progressRef.current[uid] = progressRef.current[uid] + 1;
//               }
//           },
//           40
//       );
//   });

//   return saveRequestPromise;
// };

export const PersonalInformation = (
  data,
  _formRenderProps,
  fetchFile,
  uploadFile,
  deleteFile
) => {
  //const [files, setFiles] = React.useState(data.files);

  return (formRenderProps) => (
    <div>
      <Field
        key={"expectedGraduationYear"}
        id={"expectedGraduationYear"}
        name={"expectedGraduationYear"}
        label={"Expected Graduation Year"}
        component={DropDownList}
        textField="text"
        dataItemKey="id"
        //onChange={fieldChange}
        data={expectedGraduationYearsOptions()}
        //data={expectedGraduationYearsOptions}
        //validator={(d) => fieldValidator(d,"type", triggerEventMutation.error, requiredValidator)}
      />
      <Field
        name={"dateOfBirth"}
        label={"Date of Birth"}
        hintDirection={"end"}
        component={FormDatePicker}
        // onChange={(event) => {
        //   //debugger;
        //   formRenderProps.onChange("undergradSchoolDegreeMajor", {
        //     value: "test",
        //   });
        // }}
        //disabled={!isNew}
        //validator={(d) => fieldValidator(d,"triggerAt", triggerEventMutation.error)}
        format="dd-MMM-yyyy"
      />
      <Field
        key={"highSchoolCityState"}
        id={"highSchoolCityState"}
        name={"highSchoolCityState"}
        label={"High School/City/State"}
        component={FormInput}
        //validator={emailValidator}
      />

      {/* <Field
        key={"gradSchoolDegreeField"}
        id={"gradSchoolDegreeField"}
        name={"gradSchoolDegreeField"}
        label={"Grad School/Degree/Field (if applicable)"}
        hint={"if applicable"}
        component={FormInput}
        //validator={emailValidator}
      /> */}

      <div className="k-form-field">
        <label className="k-label">Upload files:</label>
        <Field
          key={"files"}
          id={"files"}
          name={"files"}
          label={"files"}
          batch={false}
          optional={false}
          defaultFiles={data?.files.map((f) => ({ ...f, status: 4 })) ?? []}
          // defaultFiles={[s
          //   {
          //     uid: "4AA36A9E-EBCD-4345-8D44-49429AE93562",
          //     name: "test2.pdf",
          //     //extension: "pdf",
          //   },
          // ]}
          multiple={true}
          hint={"Hint: Upload your files"}
          component={Upload}
          //listItemUI={CustomListItemUI}
          // onAdd={(event) => {
          //   debugger;
          //   formRenderProps.onChange("files", {
          //     value: event?.newState,
          //   });
          // }}
          // onRemove={(event) => {
          //   //debugger;
          //   formRenderProps.onChange("files", {
          //     value: event?.newState,
          //   });
          // }}
          // onStatusChange={(event) => {
          //   //debugger;
          //   //console.log(data);
          //   formRenderProps.onChange("files", {
          //     value: event?.newState,
          //   });
          // }}
          // onBeforeUpload={onBeforeUpload}
          // onBeforeRemove={onBeforeRemove}
          removeMethod={"DELETE"}
          withCredentials={false}
          restrictions={{
            allowedExtensions: [".pdf", ".jpg", ".jpeg"],
            maxFileSize: 4000000,
          }}
          saveUrl={uploadFile}
          //saveUrl={`https://localhost:7228/api/file/test`}
          saveField={"file"}
          //removeUrl={"https://localhost:7228/api/file/remove"}
          removeUrl={deleteFile}
        />
      </div>

      {/* {data.files.map((a) => a.name)} */}
    </div>
  );
};
